<template>
  <pagination-table action="users/fetchUsers" :meta="users.meta" :filterable="false">
    <template v-slot:columns>
      <th>
        <a @click="sortUsers('name')" class="list-sort text-muted" data-sort="item-name" href="#">Nome</a>
      </th>
      <th>
        <a @click="sortUsers('email')" class="list-sort text-muted" data-sort="item-email" href="#">E-mail</a>
      </th>
      <th>
        <a @click="sortUsers('extension')" class="list-sort text-muted" data-sort="item-branch" href="#">Ramal</a>
      </th>
      <th>
        <a @click="sortUsers('status')" class="list-sort text-muted" data-sort="item-status" href="#">Status</a>
      </th>
      <th>
      </th>
    </template>
    <template v-slot:content>
      <tr v-for="(user, index) in users.data" :key="user.id">
        <td><span class="item-name text-reset">{{ user.name }}</span></td>
        <td><a class="item-email text-reset" href="mailto:john.doe@company.com">{{ user.email }}</a></td>
        <td><span class="item-branch text-reset">{{ user.extension.extension_number }}</span></td>
        <td>
          <span class="item-status badge"
                :data-bs-toggle="user.presentation.resend || user.resent ? 'tooltip' : ''"
                data-bs-placement="top"
                :title="user.presentation.tooltip"
                :class="user.presentation.class"
                @click="resendConfirmationEmail(user.presentation.resend, index, user.email)">
            {{ user.presentation.label }}
          </span>
        </td>
        <td class="text-end">
          <!-- Dropdown -->
          <div class="dropdown">
            <a class="dropdown-ellipses dropdown-toggle" href="#" role="button"
               data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fe fe-more-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link class="dropdown-item cursor-pointer" :to="`/config/user/${user.id}/edit`">
                Editar
              </router-link>
              <a v-if="user.id !== currentUser.id"
                 class="dropdown-item cursor-pointer"
                 @click="deleteUser(user.id)">
                Excluir
              </a>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </pagination-table>
</template>

<script>
import PaginationTable from "../../common/PaginationTable";
import {Tooltip} from 'bootstrap';

export default {
  name: "UserList",
  components: {PaginationTable},

  data() {
    return {
      filter: true,
      sortAsc: true,
    }
  },

  computed: {
    users() {
      const users = this.$store.getters['users/getUsers'];
      try {
        for (let i = 0; i < users.data.length; i++) {
          users.data[i].resent = false;

          if (!users.data[i].confirmation_code) {
            users.data[i].presentation = {
              label: 'Aguardando aceite do convite',
              class: 'bg-secondary-soft cursor-pointer',
              resend: true,
              tooltip: 'Clique para reenviar o e-mail de confirmação'
            };
            continue;
          }

          users.data[i].presentation = {
            label: 'Ativo',
            class: 'bg-success-soft',
            resend: false,
            tooltip: ''
          }
        }
        return users;
      } catch (e) {
        return [];
      }
    },

    currentUser() {
      return this.$store.getters['auth/user'];
    },

    filters() {
      return this.$store.getters['users/getFilter'];
    }
  },

  watch: {
    filters() {
      this.filter = this.filters.value;
    }
  },

  mounted() {
    this.$store.dispatch('users/fetchUsers', {page: 1, search: ''});

    Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
        .forEach(tooltipNode => new Tooltip(tooltipNode))
  },

  methods: {
    setFilter() {
      const query = this.filter === true
          ? {query: 'active', value: true}
          : (this.filter === false
              ? {query: 'active', value: false}
              : {query: '', value: ''});

      this.$store.dispatch('users/setFilters', query);
    },

    clearFilter() {
      this.filter = '';
      this.setFilter();
    },

    deleteUser(userId) {
      this.$swal({
        title: 'Você realmente deseja excluir esse usuário?',
        text: 'Você não pode reverter essa ação',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('users/fetchDeleteUser', userId);
          this.$swal('Exclusão realizada!', 'Usuário excluído com sucesso!', 'success')
        } else {
          this.$swal('Exclusão cancelada', 'Usuário não excluído', 'info')
        }
      });
    },

    resendConfirmationEmail(resend, index, email) {
      if (resend) {
        this.users.data[index].resent = true;
        this.users.data[index].presentation = {
          label: 'Convite reenviado',
          class: 'bg-warning-soft',
          resend: true,
          tooltip: 'Convite já reenviado, aguardando confirmação!'
        }

        this.$store.dispatch('users/fetchResendConfirmationEmail', {email: email});
      }
    },

    sortUsers(column) {
      this.sortAsc = !this.sortAsc;
      let sortAsc = this.sortAsc;

      function sort(a, b) {
        if (a > b) {
          return sortAsc ? 1 : -1;
        }
        if (a < b) {
          return sortAsc ? -1 : 1;
        }
        return 0;
      }

      this.users.data.sort(function (a, b) {
        if (column === 'name') {
          return sort(a.name, b.name);
        }
        if (column === 'email') {
          return sort(a.email, b.email);
        }
        if (column === 'extension') {
          return sort(a.extension.extension_number, b.extension.extension_number);
        }
        if (column === 'status') {
          return sort(a.presentation.label, b.presentation.label);
        }
      });
    },

  }
}
</script>

<style scoped>
td {
  height: 20px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
</style>
