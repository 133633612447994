<template>
  <div>
    <button @click="resetData()" type="button" class="btn btn-primary raise" data-bs-toggle="modal"
            data-bs-target="#inviteUserModal">
      Adicionar usuários
    </button>

    <div class="modal fade" id="inviteUserModal" tabindex="-1" aria-labelledby="inviteUserModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="col-12" v-if="step === 1">
              <p class="h1 my-4 text-center">Convide quantas pessoas quiser</p>
              <p class="text-center phrase">Quem mais irá utilizar o Qevo em sua empresa?</p>
              <p class="text-center phrase">Informe o e-mail das pessoas da sua equipe para convidá-las:</p>

              <div class="container my-5" style="max-height: 320px; overflow: auto">
                Endereço de e-mail
                <div v-for="(item, index) in emails" v-bind:key="index+item" class="mb-2">
                  <input v-model.lazy="emails[index]"
                         class="form-control"
                         :ref="`email${index}`"
                         :class="'emails.'+index in errors || index in errorsInFront ? 'is-invalid' : ''"
                         placeholder="Digite o endereço de e-mail para convite"
                         @focus="newInput(index)">
                  <div v-if="'emails.'+index in errors ? 'is-invalid' : ''" class="invalid-feedback">
                    <span v-if="'emails.'+index in errors">
                      {{ errors[`emails.${index}`][0] }}
                    </span>
                  </div>
                  <div v-if="index in errorsInFront ? 'is-invalid' : ''" class="invalid-feedback">
                    Formato de e-mail inválido.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 py-2" v-else>
              <p class="h1 my-4 text-center">Tudo Pronto</p>

              <div class="row">
                <img
                    src="../../../assets/img/illustrations/happiness.svg"
                    height="175"
                    alt="..."
                />
              </div>
              <p class="text-center mt-4">As pessoas convidadas receberam um e-mail com um link para acessarem a sua
                conta no
                Qevo</p>

              <p class="text-center">Você já pode começar a realizar ligações!</p>
            </div>
          </div>
          <div class="modal-footer" v-if="step === 1">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
            <button v-if="errorsInFront.length === 0" type="button" class="btn btn-primary" @click="invite()">
              Continuar
            </button>
            <button v-else type="button" class="btn btn-primary" disabled>Continuar</button>
          </div>
          <div class="modal-footer" v-else>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Concluir</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InviteUsers",
  data() {
    return {
      emails: [
        '',
        ''
      ],
      names: [
        '',
        ''
      ],
      errorsInFront: [],
      step: 1,
    }
  },

  created() {
    this.$store.dispatch('auth/setInviteUserStatus', false);
  },

  mounted() {
    this.$refs.email0.focus();
  },

  computed: {
    errors() {
      return this.$store.getters['auth/getInviteUsersErrors'] || {};
    },

    invitedUsersStatus() {
      return this.$store.getters['auth/getInvitedUsersStatus'];
    }
  },

  watch: {
    invitedUsersStatus() {
      if (this.invitedUsersStatus === true) {
        this.step = 2;
      }
    },
  },

  methods: {
    newInput(index) {
      this.errorsInFront = [];

      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] !== '') {
          if (!this.validateEmail(this.emails[i])) {
            this.errorsInFront[i] = true;
          }
        }
      }

      if (this.errorsInFront.length > 0) {
        return;
      }

      if (this.emails.length - 1 < index + 1) {
        this.emails.push('')
        this.names.push('')
      }
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    invite() {
      this.$store.dispatch('auth/inviteUsers', {
        names: this.names,
        emails: this.emails
      });
    },

    resetData() {
      this.step = 1;
      this.emails = ['', ''];
      this.names = ['', ''];
      this.errorsInFront = [];
      this.$store.dispatch('auth/resetInviteUsersErrors');
    }
  }
}
</script>

<style scoped>
.phrase {
  margin-bottom: 2px !important;
}

.modal-body {
  height: 560px !important;
}
</style>
