<template>
  <layout header-pre-title="Configurações" header-title="Usuários">
    <template v-slot:page-action-button>
      <invite-users></invite-users>
    </template>
    <template v-slot:content>
      <user-list></user-list>
    </template>
  </layout>
</template>

<script>
import layout from "../../../components/layout/Layout";
import UserList from "../../../components/configs/users/UsersList";
import InviteUsers from "../../../components/configs/users/InviteUsers";

export default {
  components: {
    InviteUsers,
    UserList,
    layout
  },
  name: "User"
}
</script>

<style scoped>

</style>